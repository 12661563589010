import axios from 'axios';
import { useMainStore } from '../stores/index'
// const instagramUserId = config.public.instagramUserId;
export default {
  data() {
    return {
      selectedIndex: 2, // Store the selected index
      mainStore: null
    };
  },
  created() {

    this.mainStore = useMainStore()
    // console.log(this.mainStore, "this.mainStore")
  },
  mounted() {
    // console.log(this.$config.public.apiBasePath, "mounted mixin")
  },
  methods: {

    async fetch(endPoint, options = { secure: true, multipart: false }) {
      options = options.multipart ? options : {
        ...options,
        multipart: false
      };
      let result = await this._execRequest({
        method: 'get',
        url: endPoint
      }, options);
      return !result ? [] : result;
    },
    async post(endPoint, dataBody, options = { secure: true, multipart: false }) {
      // console.log("from mixin post")
      options = options.multipart ? options : {
        ...options,
        multipart: false
      };
      return await this._execRequest({
        method: 'post',
        url: endPoint,
        data: dataBody
      }, options);
    },
    async put(endPoint, dataBody, options = { secure: true, multipart: false }) {
      options = options.multipart ? options : {
        ...options,
        multipart: false
      };
      return await this._execRequest({
        method: 'put',
        url: endPoint,
        data: dataBody
      }, options);
    },
    toast(toastType, message) {
      this.mainStore.triggerSnackbar(message, toastType);
    },
    _execRequest(config, options = { secure: true, multipart: false }) {
      function promiseCallback(resolve) {
        let headers = {
          'Content-Type': options.multipart ? 'multipart/form-data' : 'application/json',
        };
        headers = options.secure ? {
          ...headers,
          "authorization": 'Bearer ' + this.mainStore.loginToken
        } : headers;
        config.url = `${this.$config.public.apiBasePath}/${options.secure ? 'secure' : 'auth'}/${config.url}`;
        // console.log('Request: ', { ...config, headers: headers });
        axios({ ...config, headers: headers })
          .then(res => {
            const errCode = res.data.errCode;
            const data = res.data;
            console.log(errCode, "errCode")
            if (errCode === -1) {
              console.log("errrr", typeof data.errMsg)
              if (typeof data.errMsg === 'string') {
                if (config.method === 'post' || config.method === 'put') {
                  // console.log(config , '->>>>>> DKCONFIG')
                  var checkStr = config.url
                  if (checkStr.includes('secure/user-management/add-visitors')) {
                    console.log('Dont show toast')
                  } else {
                    console.log("elseeeee")
                    this.toast(data.resType, data.errMsg);
                  }
                }
              } else {
                if (config.url.includes('login/dc/v1')) {
                  this.toast(data.resType, 'User Logged in Successfully',);
                }
                if (config.url.includes('user-management/update-profile/dc/v1')) {
                  this.toast(data.resType, 'Profile Updated Successfully',);
                }

              }

              resolve(data.errMsg);
            } else {
              // console.log("else patt")
              if (config.method === 'post' || config.method === 'put') {
                this.toast(data.resType, data.errMsg);
                // alert(data.errMsg)
              }
              resolve(null);
              // resolve(data.errMsg);
            }
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response, "catch")
              this.toast('error', 'Request failed');
              if (config.method === 'post' || config.method === 'put') {
                this.toast(error.response.data.resType, error.response.data.errMsg);
              }

              if (error.response.status === 400 || error.response.status === 401) {
                try {
                  // validation error is comes in 400
                  // custom error code validation needs to apply here
                  // VALIDATION_ERROR = 81
                  if (error.response.data.errCode === 81) {
                    // for validation error purpos
                  }
                  // store and local storage will clear on token expired, invalid
                  // if token is not sent from the application
                  // we consider this type of request is "invalid"
                  else if (error.response.data.errCode === 25) {
                    let self = this;
                    // this.$router.push('/');
                    // setTimeout(() => {
                    //   self.resetStore();
                    //   localStorage.setItem('vue_store', process.env.state);
                    // }, 3000);
                  }
                } catch (error) {
                  console.log(error);
                }
              }
            } else if (error.request) {
              console.log("ese if")
              this.toast('error', 'Request failed');
            } else {
              console.error(error, "else last");
              this.toast('error', `${error.name}: ${error.message}`);
            }
            resolve(null);
          })
      }
      return new Promise(promiseCallback.bind(this));
    },

  }
}